import { Button, Form, Input, message, Select } from "antd";

import { LoginOutlined, HomeFilled } from "@ant-design/icons";
import { Row, Col } from "antd";
import { useParams } from "react-router-dom";
import "../style/CreateUser.css";
import { useEffect, useState } from "react";
import axios from "axios";
// import Cookies from "js-cookie";
const UpdateUser = () => {
  const { id } = useParams();
  const [storeData, setStoreData] = useState({});
  const [form] = Form.useForm();
  //todo this is get API for User
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://rebaz-cars.com/api/users/${id}`
        );
        setStoreData(response.data);
        // console.log("fet api", response.data);
        form.setFieldsValue({
          username: response.data.username || "",
          email: response.data.email || "",
          number: response.data.number || "",
          address: response.data.address || "",
          role: response.data.role || "",
        });
      } catch (error) {
        console.error("Error fetching store data:", error);
      }
    };
    fetchData();
  }, [id, form]);
  //todo update user
  const onFinish = async (values) => {
    try {
      // console.log("Form values before update:", values);

      const updatedValues = {
        ...values,
        username: values.username,
        email: values.email,
        number: values.number,
        address: values.address,
        role: values.role,
      };
      // console.log("Updated values to be sent to the API:", updatedValues);

      const response = await axios.put(
        `https://rebaz-cars.com/api/users/${id}`,
        updatedValues
      );

      if (response.status === 200) {
        message.success("Successfully updated");
        // console.log("Profile updated successfully");
      }
      //  else {
      //   message.error("Failed to update");
      // }
    } catch (error) {
      message.error("Failed to update");
    }
  };
  const onFinishFailed = () => {
    // console.log("Failed:", errorInfo);
    message.error("Error");
  };
  return (
    <div>
      {/* <div className="login-container"></div> */}
      <div className="login-form-container">
        <Form
          // validateMessages={validateMessages}
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="login-form-title"> Update Information</div>
          {/* //todo this is User Name */}
          <Form.Item
            name="username"
            label={<span className="form-label">Username</span>}
          >
            <Input />
          </Form.Item>
          {/* //todo this is Email */}
          <Form.Item
            name="email"
            label={<span className="form-label">Email</span>}
          >
            <Input />
          </Form.Item>
          {/* //todo this is Role */}
          <Form.Item
            label={<span className="form-label">Role</span>}
            name="role"
          >
            <Select style={{ width: "84%" }}>
              <Select.Option value="admin">admin</Select.Option>
              <Select.Option value="user">user</Select.Option>
            </Select>
          </Form.Item>
          {/* //todo this is Address */}
          <Form.Item
            name="address"
            label={<span className="form-label">Address Location</span>}
          >
            <Input />
          </Form.Item>
          {/* //todo this is Phone */}

          <Form.Item
            name="number"
            label={<span className="form-label">Phone Number</span>}
          >
            <Input />
          </Form.Item>
          {/* //todo this is end form ========================== */}
          {/* <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
            <Button type="primary" htmlType="submit" icon={<LoginOutlined />}>
              Create
            </Button>
          </Form.Item> */}
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 12,
              xs: { span: 24 },
              sm: { span: 12, offset: 6 },
            }}
            style={{ textAlign: "center" }}
          >
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    backgroundColor: "#f44336",
                    borderColor: "#f44336",
                  }}
                  icon={<LoginOutlined />}
                >
                  Create
                </Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button
                  type="primary"
                  href="/User"
                  style={{
                    width: "100%",
                    backgroundColor: "#2196f3",
                    borderColor: "#2196f3",
                  }}
                >
                  <HomeFilled />
                  Back
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UpdateUser;
