import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import Cookies from "js-cookie";
import Login from "./Front-end/Login/Login";

const App = () => {
  const isUserAuthenticated = () => {
    const user = Cookies.get("user");

    return user != undefined;
    // const sessionToken = localStorage.getItem("sessionToken");
    // return sessionToken !== null;
  };

  return (
    <Router>
      <Routes>
        <Route
          path="*"
          element={isUserAuthenticated() ? <Home /> : <Login />}
        />
      </Routes>
    </Router>
  );
};

export default App;
