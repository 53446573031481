// AllProduct.js
import { Button, Input, List, Select } from "antd";
import { ArrowUpOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import React, { useEffect, useState } from "react";
import "../style/AllProducts.css";
// import Cookies from "js-cookie";
const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [originalProducts, setOriginalProducts] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [statusFilter, setStatusFilter] = useState(0); // State for status filter
  //todo get all Products
  const { Option } = Select; // Destructure Option from Select
  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://rebaz-cars.com/api/products`);
      const data = await response.json();

      // Ensure that `data.data` is an array
      const formattedProducts = data.data.map((item) => ({
        key: item.id,
        id: item.id,
        image_url: item.image_url
          ? `https://rebaz-cars.com/api/storage/${item.image_url}`
          : null,
        vin: item.vin,
        lot: item.lot,
        make: item.make,
        status: item.status,
        assignee: item.assignee?.username || "Unassigned", // Use optional chaining
      }));

      setOriginalProducts(formattedProducts);
      setProducts(formattedProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const onSearch = (value) => {
    setSearchValue(value);
    const filteredProducts = originalProducts.filter(
      (product) =>
        (product.vin &&
          product.vin.toLowerCase().includes(value.toLowerCase())) ||
        (product.lot &&
          product.lot.toLowerCase().includes(value.toLowerCase())) ||
        (product.assignee &&
          product.assignee.toLowerCase().includes(value.toLowerCase()))
    );
    setProducts(filteredProducts);
  };
  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return "All Vehicles";
      case 1:
        return "On The Way";
      case 2:
        return "Arrived";
      default:
        return "Unknown Status";
    }
  };
  const getStatusClass = (status) => {
    switch (status) {
      case 0:
        return "status-all-vehicles";
      case 1:
        return "status-on-the-way";
      case 2:
        return "status-arrived";
      default:
        return "";
    }
  };
  // Filter products based on selected status filter
  const filterProductsByStatus = (status) => {
    if (status === 0) {
      setProducts(originalProducts); // Show all products if "All Vehicles" is selected
    } else {
      const filteredProducts = originalProducts.filter(
        (product) => product.status === status
      );
      setProducts(filteredProducts);
    }
  };

  // Handle status filter change
  const handleStatusChange = (value) => {
    setStatusFilter(value);
    filterProductsByStatus(value);
  };
  return (
    <div>
      <Input.Search
        placeholder="search"
        onSearch={onSearch}
        enterButton
        onChange={(e) => setSearchValue(e.target.value)} // Update the search value when the input changes
        value={searchValue} // Set the input value to the search value state
        style={{ textAlign: "center" }}
      />
      <hr />
      <div className="select-status-filter-container">
        <Select
          value={statusFilter}
          onChange={handleStatusChange}
          style={{ margin: "20px 0", width: 200 }}
          className="select-status-filter"
        >
          <Option value={0}>All Vehicles</Option>
          <Option value={1}>On The Way</Option>
          <Option value={2}>Arrived</Option>
        </Select>
      </div>
      <List
        dataSource={products}
        loading={loading}
        renderItem={(item) => (
          <List.Item>
            <Link
              to={{
                pathname: `/Products/ShowProducts/${item.id}`,
              }}
            >
              {item.image_url && (
                <img
                  src={item.image_url}
                  alt="product"
                  height={120}
                  width={120}
                />
              )}
            </Link>
            <div className="product-details">
              <span className={`product-status ${getStatusClass(item.status)}`}>
                {getStatusText(item.status)}
              </span>
              <br />
              <span className="product-assignee">{item.assignee}</span>
              <br />
              Make: {item.make}
              <br />
              vin: {item.vin}
              <br />
              lot: {item.lot}
              <br />
            </div>
          </List.Item>
        )}
      />

      <Button
        type="primary"
        className="scroll-to-top-button"
        onClick={scrollToTop}
      >
        <ArrowUpOutlined />
      </Button>
      <Link to="/PostProducts">
        <Button
          type="primary"
          className="navigate-to-new-page-button"
          // onClick={toggleDarkMode}
        >
          <PlusOutlined />
        </Button>
      </Link>
    </div>
  );
};

export default Products;
