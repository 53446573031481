import React, { useState } from "react";
import { Button, Form, Input, message, Select } from "antd";
import { SaveFilled, HomeFilled } from "@ant-design/icons";
import { Row, Col } from "antd";
import "../style/CreateUser.css";

// import Cookies from "js-cookie";

const CreateUser = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState(0);
  const [form] = Form.useForm();
  //todo Post new user or admin
  //todo this is POST for user and admin
  const PostRegister = () => {
    const URI = "https://rebaz-cars.com/api/register";
    const requestBody = {
      username,
      email,
      address,
      number,
      password,
      role,
    };
    fetch(URI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        form.resetFields();
        message.success("Successfully added member");
        window.location.href = "/";
        return res.json();
      })
      .catch((error) => {
        message.error("Error: " + error.message);
      });
  };
  // .then((res) => {
  //   if (!res.ok) {
  //     throw new Error(`HTTP error! Status: ${res.status}`);
  //   }
  //   return res.json();
  // })
  // .then((data) => {
  //   form.resetFields();
  //   message.success("User created successfully!");
  //   window.location.href = "/dashboard"; // Redirect to the dashboard on successful registration
  // })
  // .catch((error) => {
  //   message.error("Error: " + error.message);
  // });
  const onFinish = (values) => {
    // console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
    message.error("Error");
  };
  return (
    <div>
      {/* <div className="login-container"></div> */}
      <div className="login-form-container">
        <Form
          // validateMessages={validateMessages}
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="login-form-title"> Add member</div>
          {/* //todo this is User Name */}
          <Form.Item
            name={"Username"}
            label={<span className="form-label">Username</span>}
            rules={[
              {
                type: "text",
                required: true,
                message: "Email is invalid",
              },
            ]}
          >
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Item>
          {/* //todo this is Email */}
          <Form.Item
            name={"Email"}
            label={<span className="form-label">Email</span>}
          >
            <Input value={email} onChange={(e) => setEmail(e.target.value)} />
          </Form.Item>
          {/* //todo this is Role */}
          <Form.Item
            label={<span className="form-label">Role</span>}
            name="role"
            rules={[
              {
                required: true,
                message: "Role is required",
              },
            ]}
          >
            <Select
              value={role}
              onChange={(value) => setRole(value)}
              style={{ width: "84%" }}
            >
              <Select.Option value="admin">Admin</Select.Option>
              <Select.Option value="user">User</Select.Option>
            </Select>
          </Form.Item>
          {/* <Form.Item
            name={"Role"}
            label={<span className="form-label">Role</span>}
            rules={[
              {
                type: "text",
                required: true,
                message: "Email is invalid",
              },
            ]}
          >
            <Input value={role} onChange={(e) => setRole(e.target.value)} />
          </Form.Item> */}
          {/* //todo--------------------------------- */}
          {/* //todo this is password */}
          <Form.Item
            label={<span className="form-label">password</span>}
            name="password"
            rules={[
              {
                required: true,
                message: "Password is invalid",
              },
            ]}
          >
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
          {/* //todo this is Address */}
          <Form.Item
            name={"Address"}
            label={<span className="form-label">Address Location</span>}
          >
            <Input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Form.Item>
          {/* //todo this is number */}
          <Form.Item
            name={"number"}
            label={<span className="form-label">number Number</span>}
          >
            <Input value={number} onChange={(e) => setNumber(e.target.value)} />
          </Form.Item>
          {/* //todo this is end form ========================== */}
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 12,
              xs: { span: 24 },
              sm: { span: 12, offset: 6 },
            }}
            style={{ textAlign: "center" }}
          >
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    backgroundColor: "#f44336",
                    borderColor: "#f44336",
                  }}
                  onClick={PostRegister}
                  icon={<SaveFilled />}
                >
                  Save
                </Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button
                  type="primary"
                  href="/"
                  style={{
                    width: "100%",
                    backgroundColor: "#2196f3",
                    borderColor: "#2196f3",
                  }}
                >
                  <HomeFilled />
                  Dashboard
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreateUser;
