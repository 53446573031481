// import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Front-end/Navbar/NavBar";
import Dashboard from "./Front-end/pages/Dashbord";
import Products from "./Front-end/pages/Products";
import User from "./Front-end/pages/User";
import NotFound from "./Front-end/pages/NotFound";
import Profile from "./Front-end/pages/Profile";
import LogOut from "./Front-end/Login/LogOut";
import PostProducts from "./Front-end/pages/PostProducts";
import UpdateUser from "./Front-end/pages/UpdateUser";
import UpdateProducts from "./Front-end/pages/UpdateProducts";
import CreateUser from "./Front-end/pages/CreateUser";
import Login from "./Front-end/Login/Login";
import ShowProducts from "./Front-end/pages/ShowProducts";
import { useEffect, useState } from "react";
import axios from "axios";
function Home() {
  const [storeData, setStoreData] = useState({});
  useEffect(() => {
    axios
      .get(`https://rebaz-cars.com/api/products`)
      .then((response) => {
        // Ensure correct access to response data
        setStoreData(response.data.data);
      })

      .catch((error) => {
        console.error("Error fetching order details:", error);
      });
  }, []);
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/LogOut" element={<LogOut />} />
        <Route path="/Products" element={<Products />} />
        <Route
          path="/Products/ShowProducts/:id"
          element={<ShowProducts storeData={storeData} />}
        />
        <Route
          path="/Products/ShowProducts/UpdateProducts/:id"
          element={<UpdateProducts storeData={storeData} />}
        />
        <Route path="/User" element={<User />} />
        <Route path="/User/UpdateUser/:id" element={<UpdateUser />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/PostProducts" element={<PostProducts />} />
        <Route path="/CreateUser" element={<CreateUser />} />
        <Route path="/Login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default Home;
