import { Link } from "react-router-dom";
import { Table, Button } from "antd";
import { animateScroll as scroll } from "react-scroll";
import { PlusOutlined, EditOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
// import "../style/User.css";
const User = () => {
  //todo this is table all products
  const [dataSource, setDataSource] = useState([]);
  const getApiProducts = async () => {
    try {
      const res = await fetch(`https://rebaz-cars.com/api/users`);
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const data = await res.json();
      setDataSource(
        data.map((item) => {
          return {
            key: item.id,
            id: item.id,
            username: item.username,
            number: item.number,
            address: item.address,
            role: item.role,
          };
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getApiProducts();
  }, []);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "UserNames",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "action",
      render: (text, record) => (
        // <Link to={`/UpdateUser/${record.id}`}>
        <Link to={`/User/UpdateUser/${record.id}`}>
          {text}
          <Button type="primary" icon={<EditOutlined />}>
            Edit
          </Button>
        </Link>
      ),
    },
  ];

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <div>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
      <Button
        type="primary"
        className="scroll-to-top-button"
        onClick={scrollToTop}
      >
        <ArrowUpOutlined />
      </Button>
      <Link to="/CreateUser">
        <Button
          type="primary"
          className="navigate-to-new-page-button"
          // onClick={toggleDarkMode}
        >
          <PlusOutlined />
        </Button>
      </Link>
    </div>
  );
};

export default User;
