import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { UserOutlined, LockOutlined, LoginOutlined } from "@ant-design/icons";
import Logo from "../image/Logo.jpg";
import Title from "../image/TiTLE.jpg";

import "../style/login.css";

import Cookies from "js-cookie";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = () => {
    const URI = "https://rebaz-cars.com/api/login";
    const requestBody = {
      username,
      password,
    };
    fetch(URI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then(async (data) => {
        const userRole = data?.user?.role;

        if (userRole === "admin") {
          message.success("Login successful");
          Cookies.set("user", JSON.stringify(data["user"]["id"]));
          window.location.href = "/";
        } else {
          message.error("Unauthorized: Admin access only");
        }
      })
      .catch((error) => {
        message.error("Invalid login");
      });
  };
  // const handleLogin = () => {
  //   if (username.trim() === "admin" && password.trim() === "12345") {
  //     const sessionToken = "example_session_token";
  //     localStorage.setItem("sessionToken", sessionToken);
  //     window.location.href = "/";
  //   } else {
  //     alert("Invalid credentials.  Please try again :(");
  //   }
  // };

  const onFinish = (values) => {
    // console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
    message.error("Error");
  };

  return (
    <div>
      <ul>
        <li>
          <img src={Logo} alt="logo" width={200} height={200} />
        </li>
        <li>
          <img src={Title} alt="logo" width={400} height={85} />
        </li>
      </ul>
      {/* <div className="login-container"></div> */}
      <div className="login-form-container">
        <Form
          // validateMessages={validateMessages}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="login-form-title"> Sign in Continue</div>
          <Form.Item
            name={"username"}
            label={<span className="form-label">Username</span>}
            rules={[
              {
                type: "text",
                required: true,
                message: "Email is invalid",
              },
            ]}
          >
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              prefix={<UserOutlined />}
            />
          </Form.Item>

          <Form.Item
            label={<span className="form-label">password</span>}
            name="password"
            rules={[
              {
                required: true,
                message: "Password is invalid",
              },
            ]}
          >
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              prefix={<LockOutlined />}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
            <Button
              type="primary"
              htmlType="submit"
              icon={<LoginOutlined />}
              onClick={handleLogin}
            >
              login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
