import { Link, useParams } from "react-router-dom";
import "../style/ShowProducts.css";
// import { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Table, Button } from "antd";
// import { useEffect, useState } from "react";
// import Cookies from "js-cookie";
const ShowProducts = ({ storeData }) => {
  const { id } = useParams();
  const product = Array.isArray(storeData)
    ? storeData.find((item) => item.id === parseInt(id))
    : null;
  // const [loading, setLoading] = useState(true);
  // const [product, setProduct] = useState(null);
  // Handle the case where product is not found
  // useEffect(() => {
  //   if (Array.isArray(storeData)) {
  //     const foundProduct = storeData.find((item) => item.id === parseInt(id));
  //     setProduct(foundProduct);
  //   }
  //   setLoading(false);
  // }, [storeData, id]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Extract only the date part
  };
  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        let style = {}; // Initialize an empty style object
        let statusText = ""; // Initialize an empty status text

        switch (record.status) {
          case 0:
            style = {
              background: "#F6E96B",
              color: "black",
              fontSize: 14,
              fontWeight: "bold",
              padding: 5,
              borderRadius: "10px 100px / 120px",
            };
            statusText = "All Vehicles";
            break;
          case 1:
            style = {
              background: "#FF8343",
              color: "white",
              fontSize: 14,
              fontWeight: "bold",
              padding: 5,
              borderRadius: "10px 100px / 120px",
            };
            statusText = "On The Way";
            break;
          case 2:
            style = {
              background: "green",
              color: "white",
              fontSize: 14,
              fontWeight: "bold",
              padding: 5,
              borderRadius: "10px 100px / 120px",
            };
            statusText = "Arrived";
            break;
          default:
            break;
        }
        return <span style={style}>{statusText}</span>;
      },
    },
    {
      title: "Update",
      dataIndex: "Action",
      key: "action",
      render: (text, record) => (
        // <Link to={`/UpdateUser/${record.id}`}>
        <Link to={`/Products/ShowProducts/UpdateProducts/${record.id}`}>
          {text}
          <Button type="primary" icon={<EditOutlined />}></Button>
        </Link>
      ),
    },
  ];
  // if (loading) {
  //   return (
  //     <div className="loading-container">
  //       <Spin size="large" />
  //     </div>
  //   );
  // }

  // if (!product) {
  //   return (
  //     <div className="product-not-found">
  //       <h2>Please wait a little bit </h2>
  //     </div>
  //   );
  // }
  if (!product) {
    return (
      <div className="product-not-found">
        <h2>Please wait </h2>
      </div>
    );
  }
  return (
    <div className="container">
      <div className="header">
        {/* <Link to={`/Products/ShowProducts/UpdateProducts`}> */}
        <Table
          dataSource={[product]} // Wrap the product in an array
          columns={columns}
          pagination={false}
        />
        {/* <button className="updateButton">
          <EditOutlined />
          <span> </span>Update
        </button> */}
        {/* </Link> */}
      </div>
      <div className={"imageContainer"}>
        <img
          src={`https://rebaz-cars.com/api/storage/${product.image_url}`}
          alt="Product"
          className={"productImage"}
        />
        <ul className={"list"}>
          <li className={"listItem"}>
            {product.make} {product.model}
          </li>
          <li className={"listItem"}>{product.price ? product.price : "0"}$</li>
        </ul>
      </div>

      <div className={"boxContainer"}>
        <div className={"box"}>
          <h3>Vehicle Features</h3>
          <hr />
          <div className={"innerBox"}>
            <ul className={"featuresList"}>
              <li className={"featureItem"}>
                Make : <br />
                {product.make ? product.make : "N/A"}
              </li>
              <li className={"featureItem"}>
                Model : {product.model ? product.model : "N/A"}
              </li>
              <li className={"featureItem"}>
                VIN : {product.vin ? product.vin : "N/A"}
              </li>
              <li className={"featureItem"}>
                Key :{product.key ? product.key : "N/A"}
              </li>
              <li className={"featureItem"}>
                LOT : {product.lot ? product.lot : "N/A"}
              </li>
              <li className={"featureItem"}>
                Color : {product.color ? product.color : "N/A"}
              </li>
              <li className={"featureItem"}>
                Country : {product.country ? product.country : "N/A"}
              </li>
              <li className={"featureItem"}>
                State : {product.state ? product.state : "N/A"}
              </li>
            </ul>
          </div>
        </div>

        {/* Duplicate boxes can be removed if not needed */}
        <div className={"box"}>
          <h3>Shipping Details</h3>
          <hr />
          <div className={"innerBox"}>
            <ul className={"featuresList"}>
              <li className={"featureItem"}>
                Container no :{" "}
                {product.container_number ? product.container_number : "N/A"}{" "}
              </li>
              <li className={"featureItem"}>
                Arrived Date :<br />
                {product.arrived_at ? formatDate(product.arrived_at) : "N/A"}
              </li>
              <li className={"featureItem"}>
                ETA : {product.eta_at ? formatDate(product.eta_at) : "N/A"}
              </li>
              <li className={"featureItem"}>
                Purchase Date : <br />
                {product.purchased_at
                  ? formatDate(product.purchased_at)
                  : "N/A"}
              </li>
            </ul>
          </div>
        </div>

        <div className={"box"}>
          <h3>Invoice</h3>
          <hr />
          <div className={"innerBox"}>
            <ul className={"featuresList"}>
              <li className={"featureItem"}>
                Purchase Price: <br />
                {product.purchase_price ? product.purchase_price : "0"}$
              </li>
              <li className={"featureItem"}>
                Shipping : {product.shipping ? product.shipping : "N/A"}
              </li>
              <li className={"featureItem"}>
                Broker : {product.broker ? product.broker : "N/A"}
              </li>
              <li className={"featureItem"}>
                Storage : {product.storage ? product.storage : "N/A"}
              </li>
              <li className={"featureItem"}>
                Title fee : {product.title_fee ? product.title_fee : "N/A"}
              </li>
              <li className={"featureItem"}>
                Other : {product.other ? product.other : "N/A"}
              </li>
              <li className={"featureItem"}>
                Sublot : {product.sublot ? product.sublot : "N/A"}
              </li>
              {/* Purchase Date {product.purchased_at ? product.purchased_at : "N/A"} */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowProducts;
