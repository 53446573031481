import { Button, Form, Input, message, Select } from "antd";
import { SaveFilled, HomeFilled } from "@ant-design/icons";
import { Row, Col } from "antd";
import axios from "axios";
import "../style/profile.css";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
const Profile = () => {
  //todo get user by ID for now
  const [setStory, setStoreData] = useState({});
  const [form] = Form.useForm();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://rebaz-cars.com/api/users/${Cookies.get("user")}`
        );
        setStoreData(response.data);
        // console.log("fet api", response.data);
        form.setFieldsValue({
          username: response.data.username || "",
          email: response.data.email || "",
          number: response.data.number || "",
          address: response.data.address || "",
          role: response.data.role || "",
        });
      } catch (error) {
        console.error("Error fetching store data:", error);
      }
    };
    fetchData();
  }, [form]);
  //todo update profile
  const onFinish = async (values) => {
    try {
      // console.log("Form values before update:", values);

      const updatedValues = {
        ...values,
        username: values.username,
        email: values.email,
        number: values.number,
        address: values.address,
        role: values.role,
      };
      // console.log("Updated values to be sent to the API:", updatedValues);

      const response = await axios.put(
        `https://rebaz-cars.com/api/users/${Cookies.get("user")}`,
        updatedValues
      );

      if (response.status === 200) {
        message.success("You have successfully updated your profile");
        // console.log("Profile updated successfully");
      } else {
        message.error("Failed to update profile");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      message.error("Failed to update profile");
    }
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
    message.error("Error");
  };
  return (
    <div>
      {/* <div className="login-container"></div> */}
      <div className="login-form-container">
        <Form
          // validateMessages={validateMessages}
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="login-form-title"> Profile Information</div>
          {/* //todo this is User Name */}
          <Form.Item
            name="username"
            label={<span className="form-label">Username</span>}
          >
            <Input />
          </Form.Item>
          {/* //todo this is Email */}
          <Form.Item
            name="email"
            label={<span className="form-label">Email</span>}
          >
            <Input />
          </Form.Item>
          {/* //todo this is Role !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
          <Form.Item
            label={<span className="form-label">Role</span>}
            name="role"
          >
            <Select style={{ width: "84%" }}>
              <Select.Option value="admin">admin</Select.Option>
              <Select.Option value="user">user</Select.Option>
            </Select>
          </Form.Item>

          {/* //todo this is Address */}
          <Form.Item
            name="address"
            label={<span className="form-label">Address Location</span>}
          >
            <Input />
          </Form.Item>
          {/* //todo this is Phone */}
          <Form.Item
            name="number"
            label={<span className="form-label">Phone Number</span>}
          >
            <Input />
          </Form.Item>
          {/* //todo this is end form ========================== */}
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 12,
              xs: { span: 24 },
              sm: { span: 12, offset: 6 },
            }}
            style={{ textAlign: "center" }}
          >
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    backgroundColor: "#f44336",
                    borderColor: "#f44336",
                  }}
                  icon={<SaveFilled />}
                >
                  Save
                </Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button
                  type="primary"
                  href="/"
                  style={{
                    width: "100%",
                    backgroundColor: "#2196f3",
                    borderColor: "#2196f3",
                  }}
                >
                  Dashboard
                  <HomeFilled />
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Profile;
