import { useParams, useNavigate } from "react-router-dom";
import { SaveFilled, HomeFilled } from "@ant-design/icons";
import { Row, Col } from "antd";
import { Button, DatePicker, Form, Input, message, Select } from "antd";
// import { LoginOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import axios from "axios";
import "../style/UpdateProducts.css";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
const UpdateProducts = ({ storeData }) => {
  const { id } = useParams();
  const navigate = useNavigate(); // Use useNavigate for navigation
  const product = Array.isArray(storeData)
    ? storeData.find((item) => item.id === parseInt(id))
    : null;

  //todo get user by ID for now
  const [form] = Form.useForm();
  const [dataCurrencies, setDataCurrencies] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(product?.status || 0);
  const [image_url, setImage_url] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://rebaz-cars.com/api/users`);
        setDataCurrencies(response.data);
        // console.log("fet api", response.data);
      } catch (error) {
        console.error("Error fetching store data:", error);
      }
    };
    fetchData();
  }, [form]);
  //todo Add image ===============================
  const handleImageChange = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      if (file.status !== "uploading") {
        const response = await axios.post(
          "https://rebaz-cars.com/api/upload-file",
          formData
        );
        if (response.status === 200) {
          const imageUrl = `${response.data.path}`;
          setImage_url(imageUrl);
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const normFile = (e) => {
    return e && e.fileList ? [e.file] : [];
  };

  const onChangeUpload = async (e) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      await handleImageChange(file);
    }
  };
  //! end image ===========================================================
  //todo add multiple image ========================================================
  const [imageFiles, setImageFiles] = useState([]);

  const handleDeleteImage = (id) => {
    setImageFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const onChangeMultipleUpload = (event) => {
    const files = Array.from(event.target.files);
    const fileObjects = files.map((file, index) => ({
      id: Date.now() + index, // Unique identifier
      file: file,
      url: URL.createObjectURL(file), // Create object URL here
    }));
    setImageFiles((prevFiles) => [...prevFiles, ...fileObjects]);
  };

  const normMultipleFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //todo get form api
  useEffect(() => {
    if (product) {
      const imageUrl = product.image_url ? `${product.image_url}` : null;
      // console.log("imageUrl 111:", imageUrl);
      setImage_url(imageUrl);
      form.setFieldsValue({
        owner: product.owner.id,
        assigned_to: product.assigned_to,
        make: product.make || "",
        model: product.model || "",
        vin: product.vin || "",
        key: product.key || "",
        lot: product.lot || "",
        color: product.color || "",
        country: product.country || "",
        state: product.state || "",
        status: product.status || "",
        shipping: product.shipping || "",
        broker: product.broker || "",
        storage: product.storage || "",
        title_fee: product.title_fee || "",
        other: product.other || "",
        sublot: product.sublot || "",
        purchase_price: product.purchase_price || "",
        container_number: product.container_number || "",
        purchased_at: product.purchased_at
          ? moment(product.purchased_at, "YYYY-MM-DD")
          : null,
        arrived_at: product.arrived_at
          ? moment(product.arrived_at, "YYYY-MM-DD")
          : null,
        eta_at: product.eta_at ? moment(product.eta_at, "YYYY-MM-DD") : null,
        files: product.files || [], //todo get imageFiles =======================
      });
    }
    // console.log(product);
  }, [product, form]);
  useEffect(() => {
    if (product && product.files) {
      setImageFiles(product.files);
    }
  }, [product]);
  //todo update on here ====================================
  const onFinish = async (values) => {
    try {
      // Filter out images that need to be uploaded (those that have a 'file' key)
      const newImages = imageFiles.filter((image) => image.file);

      // Map over the newImages and upload each one
      const filePostPromises = newImages.map(async (image) => {
        try {
          const fileData = new FormData();
          fileData.append("file", image.file);
          // console.log("File object details:", image);

          const response = await axios.post(
            "https://rebaz-cars.com/api/upload-file",
            fileData
          );
          return response.data;
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      });

      // Wait for all files to be uploaded
      const uploadedFiles = await Promise.all(filePostPromises);

      // Combine the paths of newly uploaded files and existing files
      const allFiles = [
        ...uploadedFiles,
        ...imageFiles
          .filter((image) => !image.file)
          .map((image) => ({ id: image.id })),
      ];

      // Log the combined files array
      // console.log("allFiles", allFiles);

      // Update the form values
      const updatedValues = {
        ...values,
        purchased_at: values.purchased_at
          ? values.purchased_at.format("YYYY-MM-DD")
          : null,
        arrived_at: values.arrived_at
          ? values.arrived_at.format("YYYY-MM-DD")
          : null,
        eta_at: values.eta_at ? values.eta_at.format("YYYY-MM-DD") : null,
        status: selectedStatus,
        image_url: image_url,
        files: JSON.stringify(allFiles), // Correctly update files with the paths from the API
      };

      // console.log("Updated Values:", updatedValues);

      await axios.put(
        `https://rebaz-cars.com/api/products/${id}`,
        updatedValues
      );
      message.success("Product updated successfully!");
      navigate("/Products"); // Redirect to /Products after successful status update
      window.location.reload(); // Refresh the page after navigation
    } catch (error) {
      console.error(
        "Error updating product:",
        error.response ? error.response.data : error
      );
      message.error(
        `Failed to update product: ${
          error.response ? error.response.data.message : error.message
        }`
      );
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
    message.error("Error");
  };
  //todo Add button for start step===========================
  const handleStatusChange = async (status) => {
    setSelectedStatus(status);
    try {
      await axios.put(`https://rebaz-cars.com/api/products/${id}`, { status });
      message.success("Status updated successfully!");
      navigate("/Products"); // Redirect to /Products after successful status update
      window.location.reload(); // Refresh the page after navigation
    } catch (error) {
      console.error("Error updating status:", error);
      message.error("Failed to update status.");
    }
  };
  //! end Add button for start step===========================
  // if (loading) {
  //   return (
  //     <div className="loading-container">
  //       <Spin size="large" />
  //     </div>
  //   );
  // }

  // if (!product) {
  //   return (
  //     <div className="product-not-found">
  //       <h2>Please wait a little bit </h2>
  //     </div>
  //   );
  // }
  if (!product) {
    return (
      <div className="product-not-found">
        <h2>Please wait </h2>
      </div>
    );
  }
  return (
    <div>
      <div className="login-form-title"> Update Product Information</div>
      <div className="container">
        <div className="status-section">
          <div className="status-row">
            <span>Status</span>
            <span
              style={{
                backgroundColor:
                  product.status === 0
                    ? "#F6E96B"
                    : product.status === 1
                    ? "#FF8343"
                    : product.status === 2
                    ? "green"
                    : "black",
                color:
                  product.status === 0
                    ? "black"
                    : product.status === 1
                    ? "white"
                    : product.status === 2
                    ? "white"
                    : "white",
                padding: "5px 10px",
                borderRadius: "4px",
                display: "inline-block",
              }}
            >
              {product.status === 0 && "All Vehicles"}
              {product.status === 1 && "On The Way"}
              {product.status === 2 && "Arrived"}
            </span>
          </div>
        </div>
        <div className="button-section">
          <button
            type="button"
            style={{
              backgroundColor: "#F6E96B",
              fontWeight: "bold",
              color: "black",
            }}
            onClick={() => handleStatusChange(0)}
          >
            All Vehicles
          </button>
          <span></span>
          <button
            type="button"
            style={{
              backgroundColor: "#FF8343",
              fontWeight: "bold",
              color: "white",
            }}
            onClick={() => handleStatusChange(1)}
          >
            On The Way
          </button>
          <span></span>
          <button
            type="button"
            style={{
              backgroundColor: "green",
              fontWeight: "bold",
              color: "white",
            }}
            onClick={() => handleStatusChange(2)}
          >
            Arrived
          </button>
        </div>
      </div>
      <div className="login-form-container">
        <Form
          // validateMessages={validateMessages}
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {/* //todo image ================================================= */}
          <Form.Item
            name="image_url"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <input
              id="single-image-upload"
              type="file"
              style={{ display: "none" }}
              onChange={onChangeUpload}
            />
            <label htmlFor="single-image-upload">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                className="upload-button"
              >
                add Image
              </IconButton>
            </label>
            {image_url && (
              <div>
                <img
                  // src={image_url}
                  src={`https://rebaz-cars.com/api/storage/${image_url}`}
                  alt="Product"
                  style={{ width: "200px", height: "auto", marginTop: "10px" }}
                />
              </div>
            )}
          </Form.Item>
          {/* //!  end image ================================================= */}
          {/* //todo multiple image ============================================== */}
          <Form.Item
            name="files"
            // label="MultipleImage"
            valuePropName="fileList"
            getValueFromEvent={normMultipleFile}
          >
            <input
              id="multiple-image-upload"
              type="file"
              style={{ display: "none" }}
              onChange={onChangeMultipleUpload}
              multiple
            />
            <label htmlFor="multiple-image-upload">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                className="upload-button"
              >
                Add Multiple Images
              </IconButton>
              <div className="image-preview-container">
                {imageFiles.map(({ id, path }) => (
                  <div key={id} className="image-preview-item">
                    <img
                      // src={url}
                      src={`https://rebaz-cars.com/api/storage/${path}`}
                      alt={`Uploaded `}
                      className="image-preview"
                      width={60}
                      height={60}
                    />
                    <IconButton
                      color="secondary"
                      // aria-label="delete image"
                      component="span"
                      onClick={() => handleDeleteImage(id)}
                      className="delete-button"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ))}
              </div>
            </label>
          </Form.Item>
          {/* //! end multiple image ============================================= */}
          {/* //todo this is Role ================================================= */}
          <Form.Item
            label={<span className="form-label">Assign to User</span>}
            name="assigned_to"
          >
            <Select style={{ width: "84%" }}>
              {dataCurrencies.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.username}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="container_number"
            label={<span className="form-label">Container Number</span>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="make"
            label={<span className="form-label">Make</span>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="model"
            label={<span className="form-label">Model</span>}
          >
            <Input />
          </Form.Item>
          <Form.Item name="vin" label={<span className="form-label">VIN</span>}>
            <Input />
          </Form.Item>
          <Form.Item name="key" label={<span className="form-label">Key</span>}>
            <Input />
          </Form.Item>
          <Form.Item name="lot" label={<span className="form-label">Lot</span>}>
            <Input />
          </Form.Item>
          <Form.Item
            name="color"
            label={<span className="form-label">color</span>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="country"
            label={<span className="form-label">Country</span>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="state"
            label={<span className="form-label">State</span>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="purchase_price"
            label={<span className="form-label">Purchased Price</span>}
          >
            <Input prefix="$" />
          </Form.Item>
          <Form.Item
            name="shipping"
            label={<span className="form-label">Shipping</span>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="broker"
            label={<span className="form-label">Broker</span>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="storage"
            label={<span className="form-label">Storage</span>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="title_fee"
            label={<span className="form-label">Title Fee</span>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="other"
            label={<span className="form-label">Other</span>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="sublot"
            label={<span className="form-label">Sublot</span>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="purchased_at"
            label={<span className="form-label">Purchase Date</span>}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>{" "}
          <Form.Item
            name="arrived_at"
            label={<span className="form-label"> Arrived Date</span>}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>{" "}
          <Form.Item
            name="eta_at"
            label={<span className="form-label">ETA At</span>}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          {/* //todo this is end form ========================== */}
          {/* <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
            <Button type="primary" htmlType="submit" icon={<LoginOutlined />}>
              Save
            </Button>
          </Form.Item> */}
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 12,
              xs: { span: 24 },
              sm: { span: 12, offset: 6 },
            }}
            style={{ textAlign: "center" }}
          >
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    backgroundColor: "#f44336",
                    borderColor: "#f44336",
                  }}
                  icon={<SaveFilled />}
                >
                  Save
                </Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button
                  type="primary"
                  href="/"
                  style={{
                    width: "100%",
                    backgroundColor: "#2196f3",
                    borderColor: "#2196f3",
                  }}
                >
                  <HomeFilled />
                  Dashboard
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UpdateProducts;
