import "../style/Dashboard.css";
import User from "../image/add-user.png";
import Product from "../image/car.png";
import Allproducts from "../image/car.png";
import mediaFile from "../image/mediaFile.png";
import documentFile from "../image/documentFile.png";
import OtherFlies from "../image/OtherFlies.png";
import unknown from "../image/unknown.png";
import { Flex, Progress, Table } from "antd";
import { formatDistanceToNow } from "date-fns";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
// import Cookies from "js-cookie";
const Dashboard = () => {
  const removeAboutPrefix = (relativeTime) => {
    return relativeTime.replace(/^about\s/, "");
  };
  //todo this is table all products
  const [dataSource, setDataSource] = useState([]);
  const getApiProducts = async () => {
    try {
      const res = await fetch(`https://rebaz-cars.com/api/products`);
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const data = await res.json();
      setDataSource(
        data.data.map((item) => {
          return {
            key: item.id,
            id: item.id,
            image_url: item.image_url
              ? `https://rebaz-cars.com/api/storage/${item.image_url}`
              : null,
            status: item.status,
            created_at: item.created_at,
          };
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getApiProducts();
  }, []);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      render: (image_url) => {
        if (image_url) {
          return (
            <img
              src={image_url}
              alt="Product"
              style={{ width: "60px", height: "60px" }}
            />
          );
        } else {
          return "N/A";
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        let style = {}; // Initialize an empty style object
        let statusText = ""; // Initialize an empty status text

        switch (record.status) {
          case 0:
            style = {
              // background: "#FBB13C",
              background: "#F6E96B",
              color: "black",
              fontSize: 14,
              fontWeight: "bold",
              padding: 5,
              borderRadius: "10px 100px / 120px",
            };
            statusText = "All Vehicles";
            break;
          case 1:
            style = {
              // background: " rgb(0, 102, 255)",
              background: "#FF8343",
              color: "white",
              fontSize: 14,
              fontWeight: "bold",
              padding: 5,
              borderRadius: "10px 100px / 120px",
            };
            statusText = "On The Way";
            break;
          case 2:
            style = {
              background: "green",
              color: "white",
              fontSize: 14,
              fontWeight: "bold",
              padding: 5,
              borderRadius: "10px 100px / 120px",
            };
            statusText = "Arrived";
            break;
          default:
            break;
        }
        return <span style={style}>{statusText}</span>;
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        const date = new Date(created_at);
        return isNaN(date.getTime())
          ? "Invalid Date"
          : removeAboutPrefix(formatDistanceToNow(date, { addSuffix: true }));
      },
    },
  ];

  //todo get total products
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(0);

  useEffect(() => {
    getApiProductsNumber();
  }, []);

  const getApiProductsNumber = async () => {
    try {
      const res = await fetch(`https://rebaz-cars.com/api/products`);
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const data = await res.json();
      setTotal(data.total);
      setPerPage(data.per_page);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //todo get user
  const [totalID, setTotalID] = useState(0);
  const [totalStorage, setTotalStorage] = useState(0);

  useEffect(() => {
    getApiStorage();
  }, []);

  const getApiStorage = async () => {
    try {
      const res = await fetch(`https://rebaz-cars.com/api/analytics`);
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const data = await res.json();
      const formattedStorage = formatStorageSize(data.usedStorageSpace);
      setTotalStorage(formattedStorage);
      setTotalID(data.users);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const formatStorageSize = (sizeInBytes) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (sizeInBytes === 0) return "0 Bytes";
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    return (
      parseFloat((sizeInBytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i]
    );
  };

  return (
    <div>
      <div className="dashboard-container">
        <ul className="list-container">
          <li className="list-item">
            {/* <li className="item-content"> */}
            <span className="icon"></span>
            <Link to="/User">
              <img
                src={User}
                alt=""
                height={100}
                width={100}
                // className="warehouse-img"
              />
            </Link>
            <span className="number">User</span>
            {/* <Flex gap="small" wrap="wrap">
              <Progress
                percent={((total / perPage) * 100).toFixed(2)}
                status="active"
              />
            </Flex> */}
            <span className="user">
              {/* {total} {t("Allproducts")} {perPage} */}
              {totalID} user
            </span>
          </li>
          <li className="list-item">
            {/* <li className="item-content"> */}
            <span className="icon"></span>
            <Link to="/Products">
              <img
                src={Product}
                alt=""
                height={100}
                width={100}
                // className="warehouse-img"
              />
            </Link>
            <span className="number">Products</span>
            <Flex gap="small" wrap="wrap">
              <Progress
                percent={((total / perPage) * 100).toFixed(2)}
                status="active"
              />
            </Flex>
            <span className="user">{total} Products</span>
            {/* </li> */}
          </li>
        </ul>
      </div>

      <Table
        // dataSource={dataSource.map((item) => ({ ...item, key: item.id }))}
        dataSource={dataSource}
        columns={columns}
        scroll={{
          y: 240,
        }}
        style={{
          marginLeft: 50,
          marginRight: 50,
        }}
        pagination={false}
        title={() => (
          <div className="dashboard-header">
            <span>Recent Products</span>
            <Link to="/Products">
              <div>
                <img src={Allproducts} alt="error" height={50} width={50} />
                <span>Show All</span>
              </div>
            </Link>
          </div>
        )}
      />
      <h2 className="Storage">Storage Details {totalStorage} of 200 GB</h2>
      <div className="allFlies">
        <ul className="file-list">
          <li>
            <img src={mediaFile} alt="ff" height={70} width={70} />
          </li>
          <li>Media File</li>
          <li>{totalStorage}</li>
        </ul>
        <ul className="file-list">
          <li>
            <img src={documentFile} alt="aa" height={70} width={70} />
          </li>
          <li>Document File</li>
          <li>10.3KB</li>
        </ul>
        <ul className="file-list">
          <li>
            <img src={OtherFlies} alt="ss" height={70} width={70} />
          </li>
          <li>Other Flies</li>
          <li>5.7KB</li>
        </ul>
        <ul className="file-list">
          <li>
            <img src={unknown} alt="dd" height={70} width={70} />
          </li>
          <li>Unknown</li>
          <li>1.1KB</li>
        </ul>
      </div>
    </div>
  );
};

export default Dashboard;
