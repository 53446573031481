import {
  // Button,
  DatePicker,
  Form,
  Input,
  message,
  Select,
  InputNumber,
  // Upload,
} from "antd";
import { SaveFilled, HomeFilled } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { Button, Box, IconButton, Grid } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import axios from "axios";
import "../style/PostProducts.css";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
const PostProducts = () => {
  //todo useState for all
  const navigate = useNavigate(); // Use useNavigate for navigation
  const [container_number, setContainer_number] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [vin, setVin] = useState("");
  const [key, setKey] = useState("");
  const [lot, setLot] = useState("");
  const [color, setColor] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [shipping, setShipping] = useState("");
  const [broker, setBroker] = useState("");
  const [storage, setStorage] = useState("");
  const [title_fee, setTitle_fee] = useState("");
  const [other, setOther] = useState("");
  const [sublot, setSublot] = useState("");
  const [purchase_price, setPurchase_price] = useState(0);
  const [purchased_at, setPurchased_at] = useState(null);
  const [arrived_at, setArrived_at] = useState(null);
  const [eta_at, setEta_at] = useState(null);
  const [image_url, setImage_url] = useState(null);
  // const [imageFiles, setImageFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [assigned_to, setAssigned_to] = useState(0);
  //todo get user by ID for now
  const [form] = Form.useForm();
  const [UserApi, setUserApi] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://rebaz-cars.com/api/users`);
        setUserApi(response.data);
        // console.log("fet api", response.data);
      } catch (error) {
        console.error("Error fetching store data:", error);
      }
    };
    fetchData();
  }, [form]);
  //todo Post products =============================================================================
  const MethodPostProducts = async () => {
    try {
      const formData = new FormData();
      formData.append("owner_id", Cookies.get("user"));
      if (assigned_to != null) formData.append("assigned_to", assigned_to);
      formData.append("purchase_price", purchase_price);
      formData.append("model", model);
      formData.append("make", make);
      formData.append("vin", vin);
      formData.append("key", key);
      formData.append("lot", lot);
      formData.append("color", color);
      formData.append("country", country);
      formData.append("state", state);
      formData.append("shipping", shipping);
      formData.append("broker", broker);
      formData.append("storage", storage);
      formData.append("title_fee", title_fee);
      formData.append("other", other);
      formData.append("sublot", sublot);
      if (purchased_at != null) formData.append("purchased_at", purchased_at);
      if (arrived_at != null) formData.append("arrived_at", arrived_at);
      if (eta_at != null) formData.append("eta_at", eta_at);
      formData.append("image_url", image_url);

      //todo===================================================
      let allPostFiles = [];
      const filePostPromises = files.map(async (image) => {
        try {
          const fileData = new FormData();
          fileData.append("file", image);
          // Log the file object details
          // console.log("File object details:", image);
          const response = await axios.post(
            "https://rebaz-cars.com/api/upload-file",
            fileData
          );
          return response.data;
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      });

      const PostFiles = await Promise.all(filePostPromises);
      allPostFiles = [...allPostFiles, ...PostFiles];

      const filesArray = allPostFiles.map((filePath) => filePath);
      // console.log("filesArray", filesArray);

      formData.append("files", JSON.stringify(filesArray));

      // for (const pair of formData.entries()) {
      //   console.log(pair[0], pair[1]);
      // }
      //todo ====================================================================
      console.log("run this one");
      const response = await fetch("https://rebaz-cars.com/api/products", {
        method: "POST",
        body: formData,
      });
      // console.log("run this  two");

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      // console.log("here ", response.status);
      // console.log("run this three");
      const responseData = await response.json();
      // console.log("Response:", responseData);
      // console.log("run this four");
      message.success("You successfully added product");
      navigate("/Products"); // Redirect to /Products after successful status update
      window.location.reload(); // Refresh the page after navigation
    } catch (error) {
      console.error("Error adding product:", error);
      message.error("Failed to add product");
    }
  };
  //!end Post products =============================================================================

  const onFinish = async (values) => {
    // console.log("values", values);
    // message.success("good job ");
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
    message.error("Error");
  };
  //todo Add image ====================================================
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const handleImageChange = async (file) => {
    // Generate a preview URL for the image file
    const previewUrl = URL.createObjectURL(file);
    setImagePreviewUrl(previewUrl);

    // Proceed with the upload logic
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      "https://rebaz-cars.com/api/upload-file",
      formData
    );

    if (response.status === 200) {
      const imageUrl = response.data.path;
      setImage_url(imageUrl);
      message.success("Image uploaded successfully");

      // Revoke the preview URL after upload is successful
      URL.revokeObjectURL(previewUrl);
    } else {
      message.error("Failed to upload image");
    }
  };
  const normFile = (e) => {
    return e && e.fileList ? [e.file] : [];
  };
  const onChangeUpload = async (e) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      await handleImageChange(file);
    }
  };

  //! end image ===========================================================
  //todo add multiple image =====================================
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles([...files, ...selectedFiles]); // Add the new files to the existing files
  };

  const handleRemoveImage = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };
  //todo ===================================================================================
  //! end multiple image
  return (
    <div>
      {/* <div className="login-container"></div> */}
      <div className="login-form-container">
        <Form
          // validateMessages={validateMessages}
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="login-form-title"> Add Product </div>
          {/* //todo image ================================================= */}
          <Form.Item
            name="image-url"
            label="Image"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                type: "text",
                required: true,
                message: "Image is invalid",
              },
            ]}
          >
            <input
              id="single-image-upload"
              type="file"
              style={{ display: "none" }}
              onChange={onChangeUpload}
            />
            <label htmlFor="single-image-upload">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                className="upload-button"
              >
                Add Image
              </IconButton>
            </label>
            {imagePreviewUrl ? (
              <img
                src={imagePreviewUrl}
                alt="Preview of the selected file"
                className="image-preview"
              />
            ) : image_url ? (
              <img
                src={image_url}
                alt="Uploaded file preview"
                className="image-preview"
              />
            ) : null}
            {/* {image_url && (
              <img
                src={`https://rebaz-cars.com/storage/${image_url}`}
                alt="Uploaded"
                className="image-preview"
              />
            )} */}
          </Form.Item>
          {/* //todo multiple image ============================================== */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginBottom: 2,
            }}
          >
            <IconButton component="label">
              <UploadFileIcon sx={{ fontSize: 60, color: "#f44336" }} />
              <input
                type="file"
                multiple
                hidden
                onChange={handleFileChange}
                accept="image/*"
              />
            </IconButton>
          </Box>

          <Grid container>
            {files.map((file, index) => (
              <Grid item xs={4} key={index}>
                <Box
                  sx={{
                    position: "relative",
                    width: "50%",
                    paddingBottom: "50%",
                    overflow: "hidden",
                    borderRadius: 2,
                    border: "1px solid #ccc",
                  }}
                >
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`preview-${index}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  />
                  <Button
                    onClick={() => handleRemoveImage(index)}
                    sx={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                      minWidth: "auto",
                      // padding: "5px",
                    }}
                  >
                    X
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>

          {/* //!=============================================== */}

          {/* //! end multiple image ============================================= */}
          {/* //todo this is assignee =====================================*/}
          <Form.Item
            label={<span className="form-label">Assign to User</span>}
            name="assignee"
            rules={[
              {
                type: "text",
                required: true,
                message: "Assignee is invalid",
              },
            ]}
          >
            <Select
              style={{ width: "84%" }}
              value={arrived_at}
              onChange={(value) => setAssigned_to(value)}
            >
              {UserApi.map((assignee) => (
                <Select.Option key={assignee.id} value={assignee.id}>
                  {assignee.username}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* //todo this is  =======================================================*/}
          <Form.Item
            name="container_number"
            label={<span className="form-label">Container Number</span>}
          >
            <Input
              value={container_number}
              onChange={(e) => setContainer_number(e.target.value)}
            />
          </Form.Item>
          {/* //todo =============================================================================== */}
          <Form.Item
            name="make"
            label={<span className="form-label">Make</span>}
          >
            <Input value={make} onChange={(e) => setMake(e.target.value)} />
          </Form.Item>
          {/* //todo =============================================================================== */}
          <Form.Item
            name="model"
            label={<span className="form-label">Model</span>}
          >
            <Input value={model} onChange={(e) => setModel(e.target.value)} />
          </Form.Item>
          {/* //todo =============================================================================== */}
          <Form.Item name="vin" label={<span className="form-label">VIN</span>}>
            <Input value={vin} onChange={(e) => setVin(e.target.value)} />
          </Form.Item>
          {/* //todo =============================================================================== */}
          <Form.Item name="key" label={<span className="form-label">Key</span>}>
            <Input value={key} onChange={(e) => setKey(e.target.value)} />
          </Form.Item>
          {/* //todo =============================================================================== */}
          <Form.Item name="lot" label={<span className="form-label">Lot</span>}>
            <Input value={lot} onChange={(e) => setLot(e.target.value)} />
          </Form.Item>
          {/* //todo =============================================================================== */}
          <Form.Item
            name="color"
            label={<span className="form-label">color</span>}
          >
            <Input value={color} onChange={(e) => setColor(e.target.value)} />
          </Form.Item>
          {/* //todo =============================================================================== */}
          <Form.Item
            name="country"
            label={<span className="form-label">Country</span>}
          >
            <Input
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </Form.Item>
          {/* //todo =============================================================================== */}
          <Form.Item
            name="state"
            label={<span className="form-label">State</span>}
          >
            <Input value={state} onChange={(e) => setState(e.target.value)} />
          </Form.Item>
          {/* //todo =============================================================================== */}
          <Form.Item
            name="purchase_price"
            label={<span className="form-label">Purchased Price</span>}
          >
            <InputNumber
              prefix="$"
              value={purchase_price}
              onChange={(value) => setPurchase_price(value)}
              style={{ width: "100%", paddingLeft: 24 }}
            />
          </Form.Item>
          {/* //todo =============================================================================== */}
          <Form.Item
            name="shipping"
            label={<span className="form-label">Shipping</span>}
          >
            <Input
              value={shipping}
              onChange={(e) => setShipping(e.target.value)}
            />
          </Form.Item>
          {/* //todo =============================================================================== */}
          <Form.Item
            name="broker"
            label={<span className="form-label">Broker</span>}
          >
            <Input value={broker} onChange={(e) => setBroker(e.target.value)} />
          </Form.Item>
          {/* //todo =============================================================================== */}
          <Form.Item
            name="storage"
            label={<span className="form-label">Storage</span>}
          >
            <Input
              value={storage}
              onChange={(e) => setStorage(e.target.value)}
            />
          </Form.Item>
          {/* //todo =============================================================================== */}
          <Form.Item
            name="title_fee"
            label={<span className="form-label">Title Fee</span>}
          >
            <Input
              value={title_fee}
              onChange={(e) => setTitle_fee(e.target.value)}
            />
          </Form.Item>
          {/* //todo =============================================================================== */}
          <Form.Item
            name="other"
            label={<span className="form-label">Other</span>}
          >
            <Input value={other} onChange={(e) => setOther(e.target.value)} />
          </Form.Item>
          {/* //todo =============================================================================== */}
          <Form.Item
            name="sublot"
            label={<span className="form-label">Sublot</span>}
          >
            <Input value={sublot} onChange={(e) => setSublot(e.target.value)} />
          </Form.Item>
          {/* //todo this is  =======================================================*/}
          <Form.Item
            name="purchased_at"
            label={<span className="form-label">Purchase Date</span>}
          >
            <DatePicker
              format="YYYY-MM-DD"
              value={purchased_at}
              onChange={(date, dateString) => setPurchased_at(dateString)}
            />
          </Form.Item>
          {/* //todo this is  =======================================================*/}
          <Form.Item
            name="arrived_at"
            label={<span className="form-label"> Arrived Date</span>}
          >
            <DatePicker
              format="YYYY-MM-DD"
              value={arrived_at}
              onChange={(date, dateString) => setArrived_at(dateString)}
            />
          </Form.Item>
          {/* //todo this is  =======================================================*/}
          <Form.Item
            name="eta_at"
            label={<span className="form-label">ETA At</span>}
          >
            <DatePicker
              format="YYYY-MM-DD"
              value={eta_at}
              onChange={(date, dateString) => setEta_at(dateString)}
            />
          </Form.Item>
          {/* //todo this is end form ========================== */}
          <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={MethodPostProducts}
              startIcon={<SaveFilled />}
              style={{
                width: "48%", // Slightly reduce the width to accommodate the margin
                backgroundColor: "#f44336",
                borderColor: "#f44336",
                color: "white",
                marginRight: "4%", // Add margin to the right to create space between buttons
              }}
            >
              Save
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              startIcon={<HomeFilled />}
              href="/Products"
              style={{
                width: "48%", // Slightly reduce the width to accommodate the margin
                backgroundColor: "#2196f3",
                borderColor: "#2196f3",
                color: "white",
              }}
            >
              Back
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default PostProducts;
